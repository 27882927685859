import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerPHP($type) {
    var param = "&type=" + $type
    var url = ConfigAPI.baseURL + "php" + Session.getToken() + param;
    return instance.get(url);
  },
  mostrarPHP(id) {
    var url = ConfigAPI.baseURL + "php/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarPHP(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "php" + Session.getToken();
    return instance.post(url,params);
  },
  editarPHP(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "php/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarPHP(id) {
    var url = ConfigAPI.baseURL + "php/"+ id + Session.getToken();
    return instance.delete(url);
  },   

  guardarCodePHP(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "php/code/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },

  guardarBlockedPHP(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "php/blocked/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },  
}

export default servicesAPI;
